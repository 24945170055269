<template>
	<Slide
		right
		:width="width"
	>
		<div class="flex flex-col justify-center items-center gap-10 inter p-3">
			<div @click="goTo('')">Home</div>
			<div @click="goTo('manifesto')">Manifesto</div>
			<div @click="openTwitter()">Twitter</div>
		</div>
	</Slide>
</template>

<script>
import { Slide } from 'vue3-burger-menu';
export default {
	name: 'BurgerMenu',
	data: () => {
		return {
			width: screen.width,
		};
	},
	components: {
		Slide,
	},
	methods: {
		goTo(id) {
			this.$router.push('/' + id);
			document.querySelector('.bm-cross-button').click();
		},
		openTwitter() {
			window.open('https://twitter.com/angrybirddc_', '_blank', 'noreferrer');
		},
	},
};
</script>

<style scoped lang="scss"></style>
