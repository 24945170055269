import {createStore} from 'vuex'

export default createStore({
  state: {
    wallet: null,
    isModalMintOpen: false,
    isModalRarityOpen: false
  },
  getters: {
    getWallet: state => {
      return state.wallet;
    },
    getIsModalMintOpen: state => {
      return state.isModalMintOpen;
    },
    getIsModalRarityOpen: state => {
      return state.isModalRarityOpen;
    },
  },
  mutations: {
    changeWallet: (state, data) => {
      state.wallet = data;
    },
    changeIsModalMintOpen: (state, data) => {
      state.isModalMintOpen = data;
    },
    changeIsModalRarityOpen: (state, data) => {
      state.isModalRarityOpen = data;
    },
  },
  actions: {
    changeWallet: (context, value) => {
      context.commit('changeWallet', value);
    },
    changeIsModalMintOpen: (context, value) => {
      context.commit('changeIsModalMintOpen', value);
    },
    changeIsModalRarityOpen: (context, value) => {
      context.commit('changeIsModalRarityOpen', value);
    },
  },
  plugins: [
  ]
})
