<template>
	<div class="flex flex-row w-screen h-screen justify-center background"></div>
</template>

<script>
export default {
	name: 'HomePage',
	data() {
		return {};
	},
};
</script>

<style scoped>
.background {
	background: url('../../src/assets/images/SITE_MOBILE_BG.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center top;
}

@media screen and (min-width: 1200px) {
	.background {
		background: url('../../src/assets/images/SITE_BG.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center bottom;
	}
}
</style>
