import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/pages/Home';
import Manifesto from '@/pages/Manifesto';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/manifesto',
		name: 'Manifesto',
		component: Manifesto,
	},
	{
		path: '/:catchAll(.*)',
		component: Home,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to) {
		if (!to.hash) {
			document.getElementById('app').scrollIntoView();
		}
	},
});

export default router;
