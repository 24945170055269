<!-- src/components/YoutubeMusicPlayer.vue -->
<template>
	<div class="youtube-music-player">
		<button
			class="z-30 absolute lg:bottom-8 lg:left-8 bottom-3 left-4"
			@click="playPlaylist"
		>
			<img
				src="../assets/images/PLAY_BUTTON.png"
				class="w-20"
			/>
		</button>
		<div class="player-container">
			<div id="dummy-player"></div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		playlistId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			player: null,
			isPlayerReady: false,
		};
	},
	mounted() {
		this.initPlayer();
	},
	beforeUnmount() {
		if (this.player) {
			this.player.destroy();
		}
	},
	methods: {
		initPlayer() {
			const onPlayerReady = () => {
				this.isPlayerReady = true;
			};

			const onYouTubeIframeAPIReady = () => {
				if (this.player) return; // Prevent multiple player instances
				this.player = new window.YT.Player('dummy-player', {
					height: '360',
					width: '640',
					events: {
						onReady: onPlayerReady,
					},
				});
			};

			if (window.YT && window.YT.Player) {
				setTimeout(() => onYouTubeIframeAPIReady(), 0);
			} else {
				window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
			}
		},
		playPlaylist() {
			if (this.player && this.isPlayerReady) {
				this.player.loadPlaylist({
					listType: 'playlist',
					list: this.playlistId,
					index: 0,
					startSeconds: 0,
				});
			}
		},
	},
};
</script>

<style scoped>
.youtube-music-player .player-container {
	width: 0;
	height: 0;
	overflow: hidden;
}
</style>
