<template>
	<div id="app">
		<Header />
		<div class="self-center flex-grow w-full">
			<router-view :key="$route.path" />
		</div>
	</div>
	<YoutubePlayer playlistId="PLOyeP6T2aEu37cYcnVFOMJPm_fSe2vOca" />
</template>

<script>
import Header from '@/components/header/Header';
import YoutubePlayer from '@/components/YoutubePlayer.vue';

export default {
	name: 'App',
	components: {
		Header,
		YoutubePlayer,
	},
	methods: {},
};
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	background-color: black;
}
</style>
