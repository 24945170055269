<template>
	<div
		class="absolute flex flex-row w-full justify-between items-center my-4 px-5 lg:px-10 lg:my-10"
	>
		<router-link to="/"
			><img
				src="../../assets/images/LOGO-WHITE-ABDC.png"
				class="w-16"
		/></router-link>

		<div class="flex-row justify-center items-center gap-16 hidden 2xl:flex">
			<span class="poppins text-white text-md cursorCustom">EARLY WORM</span>
			<router-link to="/manifesto"
				><span class="poppins text-white text-md">MANIFESTO</span></router-link
			>
			<img
				src="../../assets/images/TWITTER-ICON-WHITE.png"
				class="icon cursor-pointer"
				@click="openTwitter()"
			/>
			<img
				src="../../assets/images/DISCORD-ICON-WHITE.png"
				class="icon cursorCustom"
			/>
		</div>
		<BurgerMenu class="2xl:hidden" />
	</div>
</template>

<script>
import BurgerMenu from '@/components/header/BurgerMenu';
export default {
	name: 'HeaderComp',
	components: { BurgerMenu },
	methods: {
		openTwitter() {
			window.open('https://twitter.com/angrybirddc_', '_blank', 'noreferrer');
		},
	},
};
</script>

<style scoped lang="scss">
.header {
	padding: 30px 80px;
}

.logo {
	width: 80px;
	height: 80px;
}

.icon {
	width: 30px;
	height: 30px;
}

.cursorCustom:hover {
	cursor: url('../../assets/images/ICON-X.png'), pointer;
}
</style>
